import './EventForm.css';
import { Button, Form, Input, DatePicker, Upload, InputNumber, Tooltip, Alert } from 'antd';
import { useParams } from "react-router-dom";
import { useState, useCallback } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import useFetchNavigate from './useFetchNavigate';
import useFormSubmission from './useFormSubmission';
import { getUploadURL } from './getUploadURL';
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const internalFileUpload = (id, file, folder, setFile, setPreview) => {
    setFile([{
        uid: '0',
        name: file.name,
        status: 'uploading',
    }]);
    getUploadURL(folder, id, file).then(url => {
        if(url === undefined) {
            console.log("NO URL");
            setFile([{
                uid: '0',
                name: file.name,
                status: 'error',
            }]);
        } else {
            fetch(url, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type
                }
            }).then(uploadResponse => {
                console.log(uploadResponse.status);
                if(uploadResponse.status > 200) {
                    setFile([{
                        uid: '0',
                        name: file.name,
                        status: 'error',
                    }]);
                } else {
                    const reader = new FileReader();
                    
                    reader.onload = function(e) {
                        if(setPreview) {
                            const imgElement = document.getElementById('preview');
                            imgElement.src = e.target.result; // Set the image source to the file data
                            setPreview?.(true);
                        }
                    };                    
                    reader.readAsDataURL(file); // Read the file as a Data URL
                    setFile([{
                        uid: '0',
                        name: file.name,
                        status: 'done',
                    }]);
                }
            }).catch(e => {
                console.log(e);
                setFile([{
                    uid: '0',
                    name: file.name,
                    status: 'error',
                }]);
            });
        }
    });
}

export default function ProducedShowForm() {
  useFetchNavigate();
  const { id } = useParams();
  const {
    sending,
    submitForm,
    spinner
  } = useFormSubmission('ProducedShowForm');
  const [fileListWeb, setFileListWeb] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const removeWebFile = useCallback(() => {
    setFileListWeb([]);
    setShowPreview(false);
  }, []);
  const removeFile = useCallback(() => {
    setFileList([]);
  }, []);

  const uploadWebFile = useCallback((file) => {
     internalFileUpload(id, file, "web", setFileListWeb, setShowPreview);
  }, [id]);
  const uploadFile = useCallback((file) => {
    internalFileUpload(id, file, "eventbrite", setFileList);
  }, [id]);
  if (sending) {
    return spinner; 
  }

  return (
    <div className="Contianer">
        <br />
        <div className="AlertContainer">
            <Alert 
                message="Produced Show Agreement" 
                description={`By submitting this form, you are agreeing to the following: a 70%-30% split of all ticket sales (70% to produced show and 30% to Flophouse), A 5 minutes guest spot to Flophouse's discretion, and usage of Flophouse's Eventbrite for ticket sales.`}
                showIcon type="info" 
            />
        </div>
        <h2>
            Flophouse Produced Show Intake
        </h2>
        <Form
            name="basic"
            labelCol={{
                span: 9,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: true,
            }}
            autoComplete="off"
            onFinish={submitForm}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[
                    {
                    required: true,
                    message: 'Please add your event title',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Description"
                name="description"
                rules={[
                    {
                    required: true,
                    message: 'Please add your event description',
                    },
                ]}
            >
                <TextArea
                  autoSize={{
                    minRows: 3
                  }}
                />
            </Form.Item>

            <Form.Item
                label="Time"
                name="time"
                validateStatus="info"
                help="Make sure your start and end time are both in the future"
                rules={[
                    {
                        required: true,
                        message: 'Please add your date and time',
                    },
                ]}
            >
                <RangePicker
                    use12Hours
                    showTime={{
                        format: 'HH:mm',
                    }}
                    format="YYYY-MM-DD HH:mm"
                />
            </Form.Item>

            <Form.Item
                label="Price"
                name="price"
                initialValue={15}
                rules={[
                    {
                        required: true,
                        message: 'Please add your show price',
                    },
                ]}
            >
                <InputNumber min={1} />
            </Form.Item>

            <Form.Item
                label="Main Eventbrite Image"
                name="image-main"
                rules={[
                    {
                        required: false,
                        message: 'Please upload a Eventbrite image',
                    },
                ]}
            >
                <Upload 
                    name="picture" 
                    action={uploadFile} 
                    maxCount={1} 
                    listType="picture"
                    fileList={fileList}
                    onRemove={removeFile}
                > 
                    <Button icon={<UploadOutlined />}>Image Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item
                label="300 x 300 Image"
                name="image-web"
                rules={[
                    {
                    required: false,
                    message: 'Please upload a 300 by 300 image',
                    },
                ]}
            >
                <Upload 
                    name="picture" 
                    action={uploadWebFile} 
                    maxCount={1} 
                    listType="picture"
                    fileList={fileListWeb}
                    onRemove={removeWebFile}
                > 
                    <Button icon={<UploadOutlined />}>Image Upload</Button>
                    <div style={{display: showPreview ? 'block': 'none', marginTop: 10}}>
                        Preview (Actual Size on Website):
                        <img
                            id="preview"
                            src="placeholder"
                            alt="Sample Image"
                            width="300"
                            height="300"
                        />
                    </div>
                </Upload>
            </Form.Item>
            <Tooltip placement="topLeft" title="All files must be uploaded before submitting" arrow={true}>
                <Button type="primary" htmlType="submit" disabled={fileList?.[0]?.status !== "done" || fileListWeb?.[0]?.status !== "done" } style={{float: 'right'}}>
                    Submit
                </Button>
            </Tooltip>
        </Form>
    </div>
  );
}
